import { NgModule, APP_INITIALIZER, ErrorHandler, Injector } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule, SwUpdate } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { UpdateAlertModule } from './components/update-alert/update-alert.module';
import { CacheModule } from 'ionic-cache';
import { SelectiveLoadingStrategy } from './util/SelectiveLoadingStrategy';

import { AuthService } from './providers/auth.service';
import { OptionPageModule } from './pages/logged-in/option/option.module';
import { SentryErrorhandlerService } from './providers/sentry.errorhandler.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NameArPageModule } from './pages/logged-in/name-ar/name-ar.module';
import { NamePageModule } from './pages/logged-in/name/name.module';
import { EmailPageModule } from './pages/start-pages/email/email.module';
import { PhonePageModule } from './pages/logged-in/phone/phone.module';
import { ProfilePhotoPageModule } from './pages/logged-in/profile-photo/profile-photo.module';
import { ObjectivePageModule } from './pages/logged-in/objective/objective.module';
import { SkillFormPageModule } from './pages/logged-in/skill-form/skill-form.module';
import { ExperienceFormPageModule } from './pages/logged-in/experience-form/experience-form.module';
import { IdCardPageModule } from './pages/logged-in/id-card/id-card.module';
import { UniversityPageModule } from './pages/logged-in/university/university.module';
import { NationalityPageModule } from './pages/logged-in/nationality/nationality.module';
import { DateOfBirthPageModule } from './pages/logged-in/date-of-birth/date-of-birth.module';
import { GenderPageModule } from './pages/logged-in/gender/gender.module';
import { DrivingLicensePageModule } from './pages/logged-in/driving-license/driving-license.module';
import { UploadCvPageModule } from './pages/logged-in/upload-cv/upload-cv.module';
import { PhotoActionModule } from './components/photo-action/photo-action.module';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { DocumentPicker } from '@awesome-cordova-plugins/document-picker/ngx';
import { UpdateEmailPageModule } from './pages/logged-in/update-email/update-email.module';
import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';//@ionic-native/onesignal/ngx
import { FileChooser } from '@awesome-cordova-plugins/file-chooser/ngx';
import { FilePath } from '@awesome-cordova-plugins/file-path/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { UpdateBankPageModule } from './pages/logged-in/update-bank/update-bank.module';
import { CompanyPageModule } from './pages/logged-in/company/company.module';
import { CivilIdFrontPageModule } from './pages/logged-in/civil-id-front/civil-id-front.module';
import { CivilIdBackPageModule } from './pages/logged-in/civil-id-back/civil-id-back.module';
import { CivilExpiryPageModule } from './pages/logged-in/civil-expiry/civil-expiry.module';
import { MediaCapture } from '@awesome-cordova-plugins/media-capture/ngx';
import { UploadVideoPageModule } from './pages/logged-in/upload-video/upload-video.module';
import { KuwaitiNationalPageModule } from './pages/logged-in/kuwaiti-national/kuwaiti-national.module';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
//import { CloudinaryModule } from '@cloudinary/angular-5.x';
//import * as Cloudinary from 'cloudinary-core';

import { registerLocaleData } from '@angular/common';
import localeAr from '@angular/common/locales/ar-KW';
import { LocationPageModule } from './pages/logged-in/location/location.module';
import { PasswordPageModule } from './pages/start-pages/password/password.module';
import { RegisterPageModule } from './pages/start-pages/register/register.module';
import { ModalPopPageModule } from './pages/start-pages/modal-pop/modal-pop.module';
import { ForgotPasswordPageModule } from './pages/start-pages/forgot-password/forgot-password.module';
import { InvitationFeedbackPageModule } from './pages/logged-in/invitation-feedback/invitation-feedback.module';
import { InvitationModule } from './components/invitation/invitation.module';
import { AccountStatusModule } from "./components/account-status/account-status.module";
import { WorkHistoryPageModule } from "./pages/logged-in/work-history/work-history.module";
import { PreferredTimePageModule } from './pages/logged-in/preferred-time/preferred-time.module';
import { AuthModule, AuthConfig } from '@auth0/auth0-angular';
import { domain, clientId, callbackUri } from './auth.config';
import {ProfileUrlPageModule} from "src/app/pages/logged-in/profile-url/profile-url.module";
import { IntroductionPageModule } from './pages/logged-in/introduction/introduction.module';
import { RequestViewPageModule } from './pages/logged-in/request/request-view/request-view.module';
import { AwsService } from './providers/logged-in/aws.service';
import { ChatViewPageModule } from './pages/logged-in/chat/chat-view/chat-view.module';

export function awsStartupServiceFactory(awsService) {
  return () => awsService.setConfig();
}

export function startupServiceFactory(authService) {
  return () => authService.load();
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/');
}

declare global {
  interface Window { analytics: any; }
}

const config: AuthConfig = {
  domain,
  clientId,
  redirectUri: callbackUri,
};

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    HttpClientModule,
    CKEditorModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AuthModule.forRoot(config),
    //CloudinaryModule.forRoot(Cloudinary, { cloud_name: 'studenthub' }),
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    /*IonicStorageModule.forRoot({
      name: '__payroll_candidate'
    }),*/
    CacheModule.forRoot({ keyPrefix: '__payroll_candidate_cache' }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.serviceWorker }),
    UpdateAlertModule,
    AccountStatusModule,
    OptionPageModule,
    NamePageModule,
    NameArPageModule,
    EmailPageModule,
    PhonePageModule,
    PreferredTimePageModule,
    ProfilePhotoPageModule,
    UploadVideoPageModule,
    ObjectivePageModule,
    IntroductionPageModule,
    SkillFormPageModule,
    ExperienceFormPageModule,
    IdCardPageModule,
    UniversityPageModule,
    NationalityPageModule,
    DateOfBirthPageModule,
    GenderPageModule,
    DrivingLicensePageModule,
    UploadCvPageModule,
    PhotoActionModule,
    UpdateEmailPageModule,
    UpdateBankPageModule,
    CompanyPageModule,
    LocationPageModule,
    CivilIdFrontPageModule,
    CivilIdBackPageModule,
    CivilExpiryPageModule,
    KuwaitiNationalPageModule,
    PasswordPageModule,
    RegisterPageModule,
    ModalPopPageModule,
    ForgotPasswordPageModule,
    InvitationFeedbackPageModule,
    InvitationModule,
    WorkHistoryPageModule,
    ProfileUrlPageModule,
    RequestViewPageModule,
    ChatViewPageModule
  ],
  providers: [
    {
      // Provider for APP_INITIALIZER
      provide: APP_INITIALIZER,
      useFactory: awsStartupServiceFactory,
      deps: [AwsService],
      multi: true
    },
    {
      // Provider for APP_INITIALIZER
      provide: APP_INITIALIZER,
      useFactory: startupServiceFactory,
      deps: [AuthService],
      multi: true
    },
    File,
    FileChooser,
    FilePath,
    DocumentPicker,
    AndroidPermissions,
    MediaCapture,
    OneSignal,
    SwUpdate,
    FileOpener,
    SelectiveLoadingStrategy,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ErrorHandler, useClass: SentryErrorhandlerService }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  static injector: Injector;

  constructor(public injector: Injector) {
    AppModule.injector = injector;
    
    registerLocaleData(localeAr, 'ar');
  }
}
